<div class="modal-header">
  <h5 class="modal-title text-center">Create a new batch</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="cancel()"></button>
</div>
<form [formGroup]="form" class="hasbro container-fluid p-3 form-content" (ngSubmit)="submitForm()">
  <div class="row justify-content-center">
    <div class="faux-material-form-group mt-0">
      <div class="form-control-container mb-3">
        <label for="description">Description</label>
        <input class="form-control text mt-1 pb-1" type="text" id="description" formControlName="description"
               placeholder="Description" [debounce]="750" (onDebounce)="validate('description')"
               (blur)="validate('description')" (input)="clearValidators('description')" />
        <form-errors [input]="form.get('description')"></form-errors>
      </div>
      <div class="form-control-container mb-1">
        <label for="producers">Please enter a list of Producer IDs for the new batch. They can be comma delimited, tab
          delimited, or on separate lines.</label>
        <textarea class="form-control text hasbro mt-1 pb-1" id="producers" formControlName="producers"
                  placeholder="Producer IDs *" [debounce]="750" (onDebounce)="validate('producers')"
                  (blur)="validate('producers')" (input)="clearValidators('producers')" rows="5"></textarea>
        <div *ngIf="form.get('producers').errors?.['pattern']; else errors" class="alert alert-danger p-1 mt-1 mb-0">
          <div class="small">
            Only producer IDs and delimiters (commas, tabs, and new lines) allowed
          </div>
        </div>
        <ng-template #errors>
          <form-errors [input]="form.get('producers')"></form-errors>
        </ng-template>
      </div>
    </div>
  </div>
</form>
<div class="footer revert-btn d-flex justify-content-end mx-3">
  <button type="submit" class="btn btn-action" (click)="cancel()">
    Cancel
  </button>
  <button type="submit" class="btn btn-action" [disabled]="!form.valid || validatorsCleared" (click)="submitForm()">
    Submit
  </button>
</div>