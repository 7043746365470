import { Injectable } from '@angular/core';
import { ProfileService } from "@app/core";
import { NiprRole } from "@app/management/nipr/core/roles";
import { ManagementArea } from "@core/management/management-areas";

@Injectable({
  providedIn: 'root'
})
export class ManagementAccessService {

  constructor(private readonly profileService: ProfileService) { }

  async getAvailableAreas() {
    const profile = await this.profileService.getProfile();
    if (!profile) {
      return [];
    }
    const availableAreas: ManagementArea[] = [];
    let addedAccountingArea = false;
    if (profile?.permissions) {
      for (const permission of profile?.permissions) {
        switch (permission) {
          case 'view-users':
            availableAreas.push(ManagementArea.Users);
            break;
          case 'edit-accounting-statements':
          case 'view-payments':
            if (!addedAccountingArea) {
              availableAreas.push(ManagementArea.Accounting);
              addedAccountingArea = true;
            }
            break;
          case 'edit-content':
            availableAreas.push(ManagementArea.EditContent);
            break;
          case 'manage-products':
            availableAreas.push(ManagementArea.Products);
            break;
        }
      }
    }

    if (profile?.roles?.includes(NiprRole.RampAdmin)) {
      availableAreas.push(ManagementArea.Nipr);
    }

    return availableAreas;
  }
}