import { PlatformLocation } from "@angular/common";
import { Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ModalSpinnerService, NotificationService, ToastType } from "@app/core";
import {
  AlertReport,
  BatchProducer,
  BatchReportViewModel,
  DetailsType,
  LicenseUpdate,
  NiprDetailsOptions
} from "@app/management/nipr/core/nipr.interfaces";
import { NiprService } from "@app/management/nipr/core/nipr.service";
import { ClientPagingService } from "@core/client-paging/client-paging.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'nipr-details',
  templateUrl: './nipr-details.component.html',
  styleUrl: './nipr-details.component.scss'
})
export class NiprDetailsComponent implements OnInit, OnChanges {

  @Input() options?: NiprDetailsOptions;

  @ViewChild('producersTable') producersTable: ElementRef<HTMLTableElement>;
  @ViewChild('licenseUpdatesTable') licenseUpdatesTable: ElementRef<HTMLTableElement>;

  protected displayProducers: BatchProducer[]; // displayed producers in current page
  protected displayLicenseUpdates: LicenseUpdate[]; // displayed license updates in current page
  protected readonly DetailsType = DetailsType;
  protected alertReport: AlertReport;
  protected batchReport: BatchReportViewModel;
  protected type: DetailsType;
  protected totalItemsText: string;
  protected selectedProducers: Set<number>;
  protected selectedLicenseUpdates: Set<number>;
  protected producersCount: number;
  protected licenseUpdatesCount: number;

  private _producersPage: number;
  private _licenseUpdatesPage: number;
  private _producersPageSize: number;
  private _licenseUpdatesPageSize: number;
  private _maxSize: number; // pager size
  private _screenWidth: number;
  private _licenseUpdates: LicenseUpdate[];
  private _batchProducers: BatchProducer[];

  constructor(
    private readonly modalInstance: NgbActiveModal,
    private readonly platformLocation: PlatformLocation,
    private readonly window: Window,
    private readonly clientPaging: ClientPagingService,
    private readonly nipr: NiprService,
    private readonly notify: NotificationService,
    private readonly spinner: ModalSpinnerService
  ) {
    this.displayProducers = [];
    this.displayLicenseUpdates = [];
    this.selectedLicenseUpdates = new Set<number>();
    this.selectedProducers = new Set<number>();
    this.producersCount = 0;
    this.licenseUpdatesCount = 0;
    this._producersPage = 1;
    this._licenseUpdatesPage = 1;
    this._producersPageSize = 10;
    this._licenseUpdatesPageSize = 10;
    this._maxSize = 12;
  }

  ngOnInit() {
    this.setOptions(this.options);
    this.platformLocation.onPopState(() => this.cancel());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      this.setOptions(changes.options.currentValue); // If the options are changed mid-stream by the calling component, update them
    }
  }

  protected get producersPage() {
    return this._producersPage;
  }

  protected get licenseUpdatesPage() {
    return this._licenseUpdatesPage;
  }

  protected set producersPage(page: number) {
    if (page !== this.producersPage) {
      this._producersPage = page;
      this.updateProducersDisplayData();
      if (this.producersPageSize > 10) {
        this.scrollProducersToTop();
      }
    }
  }

  protected set licenseUpdatesPage(page: number) {
    if (page !== this.licenseUpdatesPage) {
      this._licenseUpdatesPage = page;
      this.updateLicenseUpdatesDisplayData();
      if (this.licenseUpdatesPageSize > 10) {
        this.scrollLicenseUpdatesToTop();
      }
    }
  }

  protected get producersPageSize() {
    return this._producersPageSize;
  }

  protected get licenseUpdatesPageSize() {
    return this._licenseUpdatesPageSize;
  }

  protected set producersPageSize(pageSize: number) {
    if (pageSize !== this.producersPageSize) {
      this._producersPageSize = pageSize;
      this.updateProducersDisplayData();
      if (this._batchProducers.length > pageSize) {
        this.scrollProducersToTop();
      }
    }
  }

  protected set licenseUpdatesPageSize(pageSize: number) {
    if (pageSize !== this.licenseUpdatesPageSize) {
      this._licenseUpdatesPageSize = pageSize;
      this.updateLicenseUpdatesDisplayData();
      if (this._licenseUpdates.length > pageSize) {
        this.scrollLicenseUpdatesToTop();
      }
    }
  }

  protected get maxSize() {
    return this._maxSize;
  }

  protected get smallDevice() {
    return this._screenWidth <= 767;
  }

  // Detect screen size change - currently used for adjusting paging maxSize.
  @HostListener('window:resize', ['$event'])
  protected onWindowResize() {
    this._screenWidth = window.innerWidth;
    this._maxSize = this._screenWidth < 400 ? 3 : this._screenWidth < 576 ? 7 : 12;
  }

  protected get showProducersScrollButton() {
    return this.smallDevice || this.producersPageSize >= 25;
  }

  protected get showLicenseUpdatesScrollButton() {
    return this.smallDevice || this.licenseUpdatesPageSize >= 25;
  }

  protected scrollProducersToTop(allTheWayUp = false) {
    if (allTheWayUp) {
      this.window.scrollTo(0, 0);
    } else {
      this.producersTable.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  protected scrollLicenseUpdatesToTop(allTheWayUp = false) {
    if (allTheWayUp) {
      this.window.scrollTo(0, 0);
    } else {
      this.licenseUpdatesTable.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  protected get toggleAllProducersAreChecked() {
    return this._batchProducers?.length && this._batchProducers.length == this.selectedProducers.size;
  }

  protected set toggleAllProducersAreChecked(isChecked: boolean) {
    this.selectedProducers = isChecked ? new Set<number>(this._batchProducers.map(x => x.id)) : new Set<number>();
  }

  onProducerCheck(id: number, isChecked: boolean) {
    if (isChecked) {
      this.selectedProducers.add(id);
    } else {
      this.selectedProducers.delete(id);
    }
  }

  protected get toggleAllLicenseUpdatesAreChecked() {
    return this._licenseUpdates?.length && this._licenseUpdates.length == this.selectedLicenseUpdates.size;
  }

  protected set toggleAllLicenseUpdatesAreChecked(isChecked: boolean) {
    this.selectedLicenseUpdates = isChecked ? new Set<number>(this._licenseUpdates.map(x => x.id)) : new Set<number>();
  }

  onLicenseUpdateCheck(id: number, isChecked: boolean) {
    if (isChecked) {
      this.selectedLicenseUpdates.add(id);
    } else {
      this.selectedLicenseUpdates.delete(id);
    }
  }

  async retry() {
    this.spinner.start();
    try {
      if (this.alertReport) {
        await this.nipr.retryErroredAlertReportLicenseUpdates(this.alertReport.date, Array.from(this.selectedLicenseUpdates));
      }
      if (this.batchReport) {
        if (this.selectedLicenseUpdates.size) {
          await this.nipr.retryErroredBatchReportLicenseUpdates(this.batchReport.id, Array.from(this.selectedLicenseUpdates));
        }
        if (this.selectedProducers.size) {
          const producerIds: string[] = [];
          for (const id of this.selectedProducers) {
            for (const producer of this._batchProducers) {
              if (producer.id === id) {
                producerIds.push(producer.producerId);
              }
            }
          }
          await this.nipr.retryErroredBatchReportProducers(this.batchReport.id, producerIds);
        }
      }
    } catch (e) {
      this.notify.showToast('Failed to retry. Please try again later.', 'Error', ToastType.Error);
    } finally {
      this.spinner.stop();
      this.modalInstance.close();
    }
  }

  cancel() {
    this.modalInstance.dismiss({ dismissed: true });
  }

  protected getProducersNumberText(): string {
    return this.producersCount > 1 ? 'producers' : 'producer';
  }

  protected getLicenseUpdatesNumberText(): string {
    return this.licenseUpdatesCount > 1 ? 'license updates' : 'license update';
  }

  private setOptions(options: NiprDetailsOptions) {
    if (options.reportType === 'AlertReport') {
      this.alertReport = options.data as AlertReport;
    } else if (options.reportType === 'BatchReport') {
      this.batchReport = options.data as BatchReportViewModel;
    } else {
      return;
    }
    this.type = options.type;
    this.totalItemsText = options.totalItemsText;
    if (options.batchProducers) {
      this.producersCount = options.batchProducers.length;
      this._batchProducers = options.batchProducers
        .sort((a, b) => a.producerId.localeCompare(b.producerId)); // producer asc
      this.updateProducersDisplayData();
    }
    if (options.licenseUpdates?.length) {
      this.licenseUpdatesCount = options.licenseUpdates.length;
      this._licenseUpdates = options.licenseUpdates
        .sort((a, b) => a.state.localeCompare(b.state) || a.licenseNumber.localeCompare(b.licenseNumber) || a.id - b.id); // state, license number, id asc
      this.updateLicenseUpdatesDisplayData();
    }
  }

  private updateProducersDisplayData() {
    const pagingResult = this.clientPaging.getDisplayData(this._batchProducers, this.displayProducers, this.producersPageSize, this.producersPage);
    if (pagingResult !== null) {
      this.displayProducers = pagingResult.data;
      if (pagingResult.pageNumber !== this.producersPageSize) {
        this._producersPage = pagingResult.pageNumber;
      }
    }
  }

  private updateLicenseUpdatesDisplayData() {
    const pagingResult = this.clientPaging.getDisplayData(this._licenseUpdates, this.displayLicenseUpdates, this.licenseUpdatesPageSize, this.licenseUpdatesPage);
    if (pagingResult !== null) {
      this.displayLicenseUpdates = pagingResult.data;
      if (pagingResult.pageNumber !== this.licenseUpdatesPageSize) {
        this._licenseUpdatesPage = pagingResult.pageNumber;
      }
    }
  }
}
