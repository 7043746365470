import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from "@angular/router";
import { Debug, ModalSpinnerService, NotificationService, ProfileService } from "@app/core";
import { NiprArea } from "@app/management/nipr/core/nipr-areas";
import {
  AlertReport,
  AlertReportState,
  DetailsType,
  NiprDetailsOptions
} from "@app/management/nipr/core/nipr.interfaces";
import { NiprService } from "@app/management/nipr/core/nipr.service";
import { NiprDetailsComponent } from "@app/management/nipr/nipr-details/nipr-details.component";
import { ApiErrorService } from "@core/api-error.service";
import { ClientPagingService } from "@core/client-paging/client-paging.service";
import { SessionService } from "@core/storage/session.service";
import { WindowVisibilityService } from "@core/window-visibility.service";
import { filter, Subject, Subscription } from "rxjs";
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'sweep-history',
  templateUrl: './sweep-history.component.html',
  styleUrl: './sweep-history.component.scss'
})
export class SweepHistoryComponent implements OnInit, OnDestroy {
  protected autoRefresh: boolean;
  protected displayAlertReports: AlertReport[]; // displayed alerts in current page
  protected alertCount: number;
  protected isLoading: boolean;
  protected AlertReportState = AlertReportState;
  protected DetailsType = DetailsType;

  private customSessionPrefix: string;
  private sessionId: string;
  private isInitialized: boolean;
  private activeRetries: Map<number, boolean>; // report id and flag if retry request is finished
  private isActiveRoute: boolean;
  private isWindowVisible: boolean;
  private isDetailsModalOpen: boolean;

  private alertReportsSub: Subscription;
  private visibilitySub: Subscription;
  private loadingSub: Subscription;
  private routeSub: Subscription;
  private activeSub: Subscription;

  private activeSubject: Subject<void>;

  private _page: number;
  private _pageSize: number;
  private _maxSize: number; // pager size
  private _screenWidth: number;
  private _alertReports: AlertReport[]; // all alerts from server

  private readonly id: string;

  @ViewChild('sweepHistory') table: ElementRef<HTMLTableElement>;

  constructor(
    private readonly session: SessionService,
    private readonly profile: ProfileService,
    private readonly nipr: NiprService,
    private readonly window: Window,
    private readonly clientPaging: ClientPagingService,
    private readonly notify: NotificationService,
    private readonly error: ApiErrorService,
    private readonly router: Router,
    private readonly spinner: ModalSpinnerService,
    windowVisibility: WindowVisibilityService
  ) {
    this.isInitialized = false;
    this.id = uuidv4();
    this.isActiveRoute = true;
    this.isWindowVisible = true;
    this.isDetailsModalOpen = false;
    this.activeSubject = new Subject<void>();
    this._page = 1;
    this._pageSize = 10;
    this._screenWidth = window.innerWidth;
    this._maxSize = this._screenWidth < 494 ? 3 : this._screenWidth < 687 ? 7 : 12;
    this.autoRefresh = true;
    this.customSessionPrefix = '';
    this.sessionId = '';
    this.displayAlertReports = [];
    this.alertCount = 0;
    this._alertReports = [];
    this.activeRetries = new Map<number, boolean>();
    this.activeSub = this.activeSubject.subscribe(async () => {
      if (this.isInitialized && !this.isDetailsModalOpen) {
        if (this.autoRefresh) {
          if (this.isWindowVisible && this.isActiveRoute) {
            Debug.debug(`Sweep History (${this.id}) active`);
            this.subscribeToAlertReports();
          } else {
            Debug.debug(`Sweep History (${this.id}) inactive`);
            this.unsubscribeFromAlertReports();
          }
        } else if (this.isWindowVisible && this.isActiveRoute) {
          Debug.debug(`Sweep History (${this.id}) active`);
          await this.getAlertReports();
        } else {
          Debug.debug(`Sweep History (${this.id}) inactive`);
        }
      }
    });
    this.visibilitySub = windowVisibility.getVisibility().subscribe((isVisible) => {
      if (this.isWindowVisible !== isVisible) {
        this.isWindowVisible = isVisible;
        this.activeSubject.next();
      }
    });
    this.loadingSub = this.nipr.loadingAlertReports.subscribe(loading => this.isLoading = loading);
    this.routeSub = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const parts = this.router.url.split('/');
      const lastPart = parts ? parts[parts.length - 1] : '';
      if (lastPart && lastPart === NiprArea.SweepHistory) {
        if (!this.isActiveRoute) {
          this.isActiveRoute = true;
          this.activeSubject.next();
        }
      } else {
        if (this.isActiveRoute) {
          this.isActiveRoute = false;
          this.activeSubject.next();
        }
      }
    });
  }

  async ngOnInit() {
    await this.loadSessionPrefix();
    this.loadPaging();
    this.loadAutoRefresh();
    if (this.autoRefresh) {
      this.subscribeToAlertReports();
    } else {
      await this.getAlertReports();
      this.isInitialized = true;
    }
    Debug.debug(`Sweep History (${this.id}) created`);
  }

  ngOnDestroy() {
    this.alertReportsSub?.unsubscribe();
    this.visibilitySub?.unsubscribe();
    this.loadingSub?.unsubscribe();
    this.routeSub?.unsubscribe();
    this.activeSub?.unsubscribe();
    Debug.debug(`Sweep History (${this.id}) destroyed`);
  }

  protected get page() {
    return this._page;
  }

  protected set page(page: number) {
    if (page !== this.page) {
      this._page = page;
      this.savePaging();
      this.updateDisplayData();
      if (this.pageSize > 10) {
        this.scrollToTop();
      }
    }
  }

  protected get pageSize() {
    return this._pageSize;
  }

  protected set pageSize(pageSize: number) {
    if (pageSize !== this.pageSize) {
      this._pageSize = pageSize;
      this.savePaging();
      this.updateDisplayData();
      if (this.alertCount > pageSize) {
        this.scrollToTop();
      }
    }
  }

  protected get maxSize() {
    return this._maxSize;
  }

  protected get smallDevice() {
    return this._screenWidth <= 767;
  }

  // Detect screen size change - currently used for adjusting paging maxSize.
  @HostListener('window:resize', ['$event'])
  protected onWindowResize() {
    this._screenWidth = window.innerWidth;
    this._maxSize = this._screenWidth < 494 ? 3 : this._screenWidth < 687 ? 7 : 12;
  }

  protected get showScrollButton() {
    return this.smallDevice || this.pageSize >= 25;
  }

  protected scrollToTop(allTheWayUp = false) {
    if (allTheWayUp) {
      this.window.scrollTo(0, 0);
    } else {
      this.table.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  protected async toggleAutoRefresh(event: Event) {
    if (this.autoRefresh) {
      this.unsubscribeFromAlertReports();
    } else {
      this.subscribeToAlertReports();
    }
    this.autoRefresh = (event.target as HTMLInputElement).checked;
    this.saveAutoRefresh();
  }

  protected get isDataReady() {
    return this.isInitialized && this.alertCount > 0;
  }

  protected get showLoadingIndicator() {
    return !this.isInitialized && this.isLoading;
  }

  protected getAlertNumberText(): string {
    return (this.alertCount > 1) ? 'alerts' : 'alert';
  }

  protected hasRetry(reportId: number) {
    return this.activeRetries.has(reportId);
  }

  protected async retry(alertReport: AlertReport) {
    if (this.activeRetries.has(alertReport.id)) {
      return;
    }
    this.activeRetries.set(alertReport.id, false);
    await this.nipr.retryErroredAlertReport(alertReport.date);
    this.activeRetries.set(alertReport.id, true);
    if (!this.autoRefresh) { // refresh now or wait for next auto-refresh
      await this.getAlertReports();
    }
  }

  protected async openDetailsModal(report: AlertReport, type: DetailsType) {
    try {
      this.spinner.start();
      this.isDetailsModalOpen = true;
      if (this.autoRefresh) {
        this.unsubscribeFromAlertReports();
      }
      let licenseUpdates = await this.nipr.getAlertReportLicenseUpdates(report.date);
      if (type === DetailsType.Notified) {
        licenseUpdates = licenseUpdates.filter(license => license.notifyFlag === true);
      } else {
        licenseUpdates = licenseUpdates.filter(license => license.updateStateString.toLowerCase() === type.toString());
      }
      let totalText: string;
      switch (type) {
        case DetailsType.Open:
          totalText = report.openLicenseUpdateCount.toString();
          break;
        case DetailsType.Imported:
          totalText = report.importedLicenseUpdateCount.toString();
          break;
        case DetailsType.Notified:
          totalText = report.notifyLicenseUpdateCount.toString();
          break;
        case DetailsType.Errored:
          totalText = report.erroredLicenseUpdateCount.toString();
          break;
        case DetailsType.Ignored:
          totalText = report.ignoredLicenseUpdateCount.toString();
          break;
      }
      const options = {
        reportType: 'AlertReport',
        data: report,
        type: type,
        licenseUpdates: licenseUpdates,
        totalItemsText: totalText
      } as NiprDetailsOptions;
      await this.notify.showModalTemplate(NiprDetailsComponent, { options: options }, { size: 'xl', windowClass: 'overflow-hidden', backdropClass: 'w-100 h-100' });
    } catch (e) {
      if (!e['dismissed']) {
        this.error.processError(e, 'openDetailsModal');
      }
    } finally {
      this.isDetailsModalOpen = false;
      if (this.autoRefresh) {
        this.subscribeToAlertReports();
      } else {
        await this.getAlertReports();
      }
      this.spinner.stop();
    }
  }

  private async getAlertReports() {
    this.isLoading = true;
    const reports = await this.nipr.getAlertReports();
    if (reports !== undefined) {
      this.refreshData(reports);
    }
    this.isLoading = false;
  }

  private refreshData(reports: AlertReport[]) {
    this._alertReports = this.sortAlertReports(reports);
    this.updateDisplayData();
  }

  private sortAlertReports(alertReports: AlertReport[]) {
    return alertReports.sort((a, b) => b.date.localeCompare(a.date));
  }

  private updateDisplayData() {
    const pagingResult = this.clientPaging.getDisplayData(this._alertReports, this.displayAlertReports, this.pageSize, this.page);
    if (pagingResult !== null) {
      this.clearFinishedRetries(pagingResult.data);
      this.displayAlertReports = pagingResult.data;
      if (pagingResult.pageNumber !== this.page) {
        this._page = pagingResult.pageNumber;
      }
    }
    this.alertCount = this._alertReports?.length || 0;
  }

  private clearFinishedRetries(currentPage: AlertReport[]) {
    for (const report of currentPage) {
      if (this.hasRetry(report.id)) {
        this.activeRetries.delete(report.id);
      }
    }
  }

  private async loadSessionPrefix() {
    const profileData = await this.profile.getProfile();
    this.sessionId = profileData.id?.toString();
    this.customSessionPrefix = `portal.${profileData.id}.management.nipr.sweep-history`;
  }

  private loadPaging() {
    this._page = this.session.getItem<number>('page', 1, false, this.customSessionPrefix);
    this._pageSize = this.session.getItem<number>('pageSize', 10, false, this.customSessionPrefix);
  }

  private savePaging() {
    this.session.setItem('page', this.page, false, this.customSessionPrefix);
    this.session.setItem('pageSize', this.pageSize, false, this.customSessionPrefix);
  }

  private loadAutoRefresh() {
    const savedSessionId = this.session.getItem<string>('sessionSaved', null, false, this.customSessionPrefix);
    if (savedSessionId == this.sessionId) {
      this.autoRefresh = this.session.getItem('autoRefresh', true, false, this.customSessionPrefix);
    } else {
      this.session.setItem('sessionSaved', this.sessionId, false, this.customSessionPrefix);
      this.saveAutoRefresh();
    }
  }

  private saveAutoRefresh() {
    this.session.setItem('autoRefresh', this.autoRefresh, false, this.customSessionPrefix);
  }

  private subscribeToAlertReports() {
    this.alertReportsSub = this.nipr.autoRefreshAlertReports().subscribe((alertsReports) => {
      this.refreshData(alertsReports);
      this.isInitialized = true;
    });
  }

  private unsubscribeFromAlertReports() {
    this.alertReportsSub?.unsubscribe();
    this.alertReportsSub = null;
  }
}
