<div class="container-fluid">
  <div class="row content mx-auto">
    <div class="col px-0">
      <div class="modal-header">
        <h5 *ngIf="alertReport" class="modal-title text-center">Alert Report - {{ this.alertReport.date | date }} -
          {{ this.type | titlecase }} {{ this.totalItemsText }}
        </h5>
        <h5 *ngIf="batchReport" class="modal-title text-center">{{ this.batchReport.description }} -
          {{ batchReport.timeStamp | date }} - {{ this.type | titlecase }} {{ this.totalItemsText }}
        </h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="cancel()"></button>
      </div>
    </div>
    <div class="modal-body p-3">
      <div *ngIf="batchReport && displayProducers && type !== DetailsType.Notified && type !== DetailsType.Ignored"
           class="mb-3">
        <div class="mb-3 overflow-auto" [class.table-container-no-retry]="type !== DetailsType.Errored"
             [class.table-container-retry]="type === DetailsType.Errored">
          <table class="table table-striped outer-borders" #producersTable>
            <caption>Producers</caption>
            <thead class="thead-medium">
              <tr>
                <th *ngIf="type === DetailsType.Errored" scope="col" class="select px-0 px-md-2">
                  <input class="form-check-input d-none d-md-block" type="checkbox" title="Toggle all"
                         id="toggleAllRows" (change)="toggleAllProducersAreChecked = $event.target['checked']"
                         [checked]="toggleAllProducersAreChecked" [disabled]="producersCount === 0" />
                </th>
                <th scope="col">Status</th>
                <th scope="col">Producer</th>
                <th scope="col">Notes</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="producersCount; else producersNoResultsTemplate">
                <tr *ngFor="let producer of displayProducers">
                  <td *ngIf="type === DetailsType.Errored" data-qa data-th="Select producer for retry" class="select">
                    <input [disabled]="producersCount === 0" class="form-check-input" type="checkbox"
                           title="Select producer for retry"
                           (change)="onProducerCheck(producer.id, $event.target['checked'])"
                           [checked]="selectedProducers.has(producer.id)" />
                  </td>
                  <td data-qa data-th="Status" class="text-nowrap">{{ producer.state }}</td>
                  <td data-qa data-th="Producer">{{ producer.producerId }}</td>
                  <td data-qa data-th="Notes">{{ producer.notes }}</td>
                </tr>
              </ng-container>
              <ng-template #producersNoResultsTemplate>
                <tr>
                  <td class="no-results center" [attr.colspan]="type === DetailsType.Errored ? 4 : 3">
                    <h4 class="m-2 ms-0 text line w-100">No results found</h4>
                  </td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </div>
        <div *ngIf="producersCount > 0" class="table-footer hstack mb-3">
          <ngb-pagination *ngIf="producersCount > producersPageSize" class="mb-2 mb-sm-0"
                          [collectionSize]="producersCount" [(page)]="producersPage" [pageSize]="producersPageSize"
                          [maxSize]="maxSize"></ngb-pagination>
          <span class="mb-2 mb-sm-auto" *ngIf="producersCount">{{producersCount | number}}
            {{ getProducersNumberText() }} total</span>
          <select class="mb-2 mb-sm-0 form-select page-size" title="Set items per page" name="producersPageSize"
                  [(ngModel)]="producersPageSize">
            <option [ngValue]="10">10 items per page</option>
            <option [ngValue]="25">25 items per page</option>
            <option [ngValue]="50">50 items per page</option>
            <option [ngValue]="100">100 items per page</option>
            <option [ngValue]="200">200 items per page</option>
            <option [ngValue]="500">500 items per page</option>
            <option [ngValue]="1000">1,000 items per page</option>
          </select>
          <button *ngIf="showProducersScrollButton" type="button" class="mb-2 mb-sm-0 btn btn-primary"
                  (click)="scrollProducersToTop()">
            Back to top
          </button>
        </div>
      </div>
      <div class="mb-3">
        <div class="mb-3 overflow-auto" [class.table-container-retry]="batchReport && type === DetailsType.Errored"
             [class.table-container-no-retry]="batchReport && type !== DetailsType.Errored"
             [class.table-container-full-retry]="alertReport && type === DetailsType.Errored"
             [class.table-container-full-no-retry]="alertReport && type !== DetailsType.Errored">
          <table class="table table-striped outer-borders mb-3" #licenseUpdatesTable>
            <caption>License Updates</caption>
            <thead class="thead-medium">
              <tr>
                <th *ngIf="type === DetailsType.Errored" scope="col" class="select px-0 px-md-2">
                  <input class="form-check-input d-none d-md-block" type="checkbox" title="Toggle all"
                         id="toggleAllLicenseUpdates"
                         (change)="toggleAllLicenseUpdatesAreChecked = $event.target['checked']"
                         [checked]="toggleAllLicenseUpdatesAreChecked" [disabled]="licenseUpdatesCount === 0" />
                </th>
                <th scope="col">State</th>
                <th scope="col">License</th>
                <th scope="col">Class</th>
                <th scope="col">End Date</th>
                <th scope="col">Status</th>
                <th scope="col">NPN</th>
                <th scope="col">Producer</th>
                <th scope="col">Notes</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="licenseUpdatesCount; else licenseUpdatesNoResultsTemplate">
                <tr *ngFor="let licenseUpdate of displayLicenseUpdates">
                  <td *ngIf="type === DetailsType.Errored" data-qa data-th="Select license updates for retry"
                      class="select">
                    <input [disabled]="licenseUpdatesCount === 0" class="form-check-input" type="checkbox"
                           title="Select license update for retry"
                           (change)="onLicenseUpdateCheck(licenseUpdate.id, $event.target['checked'])"
                           [checked]="selectedLicenseUpdates.has(licenseUpdate.id)" />
                  </td>
                  <td data-qa data-th="State">{{ licenseUpdate.state }}</td>
                  <td data-qa data-th="License">{{ licenseUpdate.licenseNumber }}</td>
                  <td data-qa data-th="Class">{{ licenseUpdate.classDescription }}</td>
                  <td data-qa data-th="End Date">{{ licenseUpdate.licenseEndDate | date }}</td>
                  <td data-qa data-th="Status">{{ licenseUpdate.statusCode }}</td>
                  <td data-qa data-th="NPN">{{ licenseUpdate.npn }}</td>
                  <td data-qa data-th="Producer">{{ licenseUpdate.producerId }}</td>
                  <td data-qa data-th="Notes">{{ licenseUpdate.notes }}</td>
                </tr>
              </ng-container>
              <ng-template #licenseUpdatesNoResultsTemplate>
                <tr>
                  <td class="no-results center" [attr.colspan]="type === DetailsType.Errored ? 9 : 8">
                    <h4 class="m-2 ms-0 text line w-100">No results found</h4>
                  </td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </div>
        <div *ngIf="licenseUpdatesCount" class="table-footer hstack mb-3">
          <ngb-pagination *ngIf="licenseUpdatesCount > licenseUpdatesPageSize" class="mb-2 mb-sm-0"
                          [collectionSize]="licenseUpdatesCount" [(page)]="licenseUpdatesPage"
                          [pageSize]="licenseUpdatesPageSize" [maxSize]="maxSize"></ngb-pagination>
          <span class="mb-2 mb-sm-auto" *ngIf="licenseUpdatesCount">{{licenseUpdatesCount | number}}
            {{ getLicenseUpdatesNumberText() }} total</span>
          <select class="mb-2 mb-sm-0 form-select page-size" title="Set items per page" name="licenseUpdatesPageSize"
                  [(ngModel)]="licenseUpdatesPageSize">
            <option [ngValue]="10">10 items per page</option>
            <option [ngValue]="25">25 items per page</option>
            <option [ngValue]="50">50 items per page</option>
            <option [ngValue]="100">100 items per page</option>
            <option [ngValue]="200">200 items per page</option>
            <option [ngValue]="500">500 items per page</option>
            <option [ngValue]="1000">1,000 items per page</option>
          </select>
          <button *ngIf="showLicenseUpdatesScrollButton" type="button" class="mb-2 mb-sm-0 btn btn-primary"
                  (click)="scrollLicenseUpdatesToTop()">
            Back to top
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="type === DetailsType.Errored" class="footer revert-btn d-flex justify-content-end mx-3">
      <button type="submit" class="btn btn-action" (click)="cancel()">
        Cancel
      </button>
      <button type="submit" class="btn btn-action" (click)="retry()"
              [disabled]="(selectedProducers?.size || 0) + (selectedLicenseUpdates?.size || 0) === 0">
        Retry
      </button>
    </div>
  </div>
</div>