import { APP_BASE_HREF, DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule, UrlSerializer } from '@angular/router';
import { BatchReportsComponent } from "@app/management/nipr/batch-reports/batch-reports.component";
import { SweepHistoryComponent } from "@app/management/nipr/sweep-history/sweep-history.component";
import { CoreModule } from '@core/core.module';
import { baseHrefFactory, DirectoryService, LowerCaseUrlSerializer, ProfileService } from '@core/index';
import { ComponentRouteReuseStrategy } from "@core/routing/component-route-reuse-strategy";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { OAuthModule } from 'angular-oauth2-oidc';
import { CookieService } from 'ngx-cookie-service';
import { MarkdownModule, MARKED_OPTIONS } from 'ngx-markdown';
import { ToastrModule } from 'ngx-toastr';
import { AccountLayoutComponent } from './account/account-layout/account-layout.component';
import { AccountService } from './account/account.service';
import { DevBarComponent } from './account/dev-bar/dev-bar.component';
import { UserModalComponent } from './account/dev-bar/user-modal/user-modal.component';
import { PasswordResetComponent } from './account/password-reset/password-reset.component';
import { RequestLoginModalComponent } from './account/request-login-modal/request-login-modal.component';
import { SetPasswordComponent } from './account/set-password/set-password.component';
import { WelcomeComponent } from './account/welcome/welcome.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EnvironmentUpdateService, environmentUpdateStartupFactory } from './environment-update.service';
import { PageLayoutModule } from './page-layout';
import { profileServiceStartupFactory } from './profile-service-startup.factory';

@NgModule({
  declarations: [
    AppComponent,
    AccountLayoutComponent,
    WelcomeComponent,
    DevBarComponent,
    PasswordResetComponent,
    SetPasswordComponent,
    UserModalComponent,
    RequestLoginModalComponent
  ],
  imports: [
    CoreModule.forRoot(),
    CoreModule,
    PageLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    NgbModule,
    GoogleTagManagerModule.forRoot({
      id: 'GTM-5B9X3Z8'
    }),
    ToastrModule.forRoot({
      closeButton: true,
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true
    }),
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MARKED_OPTIONS,
        useValue: {
          gfm: true,
          breaks: true
        }
      }
    }),
    OAuthModule.forRoot()
  ],
  providers: [
    /* Note for future developers: Take care when making changes to services defined as APP_INITIALIZER. Portal client
     * will not start and users will see a blank page if the services throw and do not handle errors properly.
     **/
    {
      provide: APP_INITIALIZER,
      deps: [EnvironmentUpdateService],
      useFactory: environmentUpdateStartupFactory,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      deps: [ProfileService, DirectoryService],
      useFactory: profileServiceStartupFactory,
      multi: true
    },
    {
      provide: APP_BASE_HREF,
      useFactory: baseHrefFactory
    },
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer
    },
    EnvironmentUpdateService,
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: {
        dateFormat: 'MM/dd/yyyy'
      }
    },
    {
      provide: RouteReuseStrategy,
      useFactory: () => new ComponentRouteReuseStrategy([SweepHistoryComponent, BatchReportsComponent])
    },
    CookieService,
    AccountService,
    provideHttpClient(withInterceptorsFromDi())
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
