import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { AuthHttpInterceptor } from "@app/core";
import {
  AlertReport, BatchReport,
  BatchReportViewModel,
  CreateBatchReport,
  LicenseUpdate
} from "@app/management/nipr/core/nipr.interfaces";
import { AutoCancelHttpService } from "@core/auto-cancel-http.service";
import { environment } from "@env";
import { BehaviorSubject, firstValueFrom } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NiprService {

  private readonly _loadingAlertReports: BehaviorSubject<boolean>;
  private readonly _loadingBatchReports: BehaviorSubject<boolean>;

  constructor(
    private readonly cancel: AutoCancelHttpService,
    private readonly http: HttpClient
  ) {
    this._loadingAlertReports = new BehaviorSubject<boolean>(false);
    this._loadingBatchReports = new BehaviorSubject<boolean>(false);
  }

  get loadingAlertReports() {
    return this._loadingAlertReports.asObservable();
  }

  autoRefreshAlertReports(interval = 10000) {
    return this.cancel.autoRefresh<AlertReport[]>(`${environment.niprApiUri}alertreports/viewmodel`, true, true, true, this._loadingAlertReports, interval);
  }

  getAlertReports() {
    return this.cancel.get<AlertReport[]>(`${environment.niprApiUri}alertreports/viewmodel`, true, true, true, this._loadingAlertReports);
  }

  retryErroredAlertReport(id: string) {
    return firstValueFrom(this.http.put(`${environment.niprApiUri}alertreports/${id}/clearerror`, null, AuthHttpInterceptor.AddAuthHttpHeader));
  }

  retryErroredAlertReportLicenseUpdates(date: string, licenseIds: number[]) {
    return firstValueFrom(this.http.put(`${environment.niprApiUri}alertreports/${date}/licenseupdates/retrybatch`, licenseIds, AuthHttpInterceptor.AddAuthHttpHeader));
  }

  getAlertReportLicenseUpdates(id: string) {
    return this.cancel.get<LicenseUpdate[]>(`${environment.niprApiUri}alertreports/${id}/licenseupdates`, true, true);
  }

  get loadingBatchReports() {
    return this._loadingBatchReports.asObservable();
  }

  autoRefreshBatchReports(interval = 10000) {
    return this.cancel.autoRefresh<BatchReportViewModel[]>(`${environment.niprApiUri}batchreports/viewmodel`, true, true, false, this._loadingBatchReports, interval);
  }

  getBatchReports() {
    return this.cancel.get<BatchReportViewModel[]>(`${environment.niprApiUri}batchreports/viewmodel`, true, true, false, this._loadingBatchReports);
  }

  getBatchReportLicenseUpdates(id: number) {
    return this.cancel.get<LicenseUpdate[]>(`${environment.niprApiUri}batchreports/${id}/licenseupdates`, true, true);
  }

  getProducersBatchReport(id: number) {
    return this.cancel.get<BatchReport>(`${environment.niprApiUri}batchreports/${id}`, true, true);
  }

  retryErroredBatchReportLicenseUpdates(id: number, licenseIds: number[]) {
    return firstValueFrom(this.http.put(`${environment.niprApiUri}batchreports/${id}/licenseupdates/retry`, licenseIds, AuthHttpInterceptor.AddAuthHttpHeader));
  }

  retryErroredBatchReportProducers(id: number, producerIds: string[]) {
    return firstValueFrom(this.http.put(`${environment.niprApiUri}batchreports/${id}/producers/retry`, producerIds, AuthHttpInterceptor.AddAuthHttpHeader));
  }

  createBatchReport(newBatch: CreateBatchReport) {
    return firstValueFrom(this.http.post<CreateBatchReport>(`${environment.niprApiUri}batchReports`, newBatch, AuthHttpInterceptor.AddAuthHttpHeader));
  }
}
