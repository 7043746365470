import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class WindowVisibilityService {
  private readonly isVisible: BehaviorSubject<boolean>;

  constructor() {
    this.isVisible = new BehaviorSubject<boolean>(true);
    document.addEventListener('visibilitychange', () => {
      this.isVisible.next(!document.hidden);
    });
  }

  getVisibility() {
    return this.isVisible.asObservable();
  }
}
