import { Location } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { NameValuePair } from '@app/core/aim/name-value-pair';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionLike } from 'rxjs';
import { ModalSpinnerService } from '../modal-spinner';
import { MultiCheckSearchOptions, MultiCheckSearchResult } from './multi-check-search-selector.interface';

@Component({
  selector: 'multi-check-search-selector',
  templateUrl: './multi-check-search-selector.component.html',
  styleUrls: ['./multi-check-search-selector.component.scss']
})
export class MultiCheckSearchSelectorComponent implements OnInit, OnChanges, OnDestroy {

  private readonly defaultOptions: MultiCheckSearchOptions = {
    plurals: { singular: 'item', plural: 'items' },
    items: new Set<NameValuePair<string>>()
  };

  @Input() options?: MultiCheckSearchOptions;

  protected searchText = '';
  protected listOptions: Option[];
  protected checkedCount: number = 0;
  protected initSelectedItems: Set<string> | Set<number>;
  protected initSearchText: string;
  private readonly sub: SubscriptionLike;

  constructor(public modalInstance: NgbActiveModal, location: Location, private readonly spinner: ModalSpinnerService,) {
    this.sub = location.subscribe(() => this.closeModal(true));
    this.options = this.defaultOptions;
    this.listOptions = [];
  }

  get filteredOptions() {
    return this.listOptions.filter(option =>
      option.value?.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  get checkedItems() {
    return this.listOptions.filter(option => option.checked);
  }

  ngOnInit() {
    if (this.options.searchText) {
      this.searchText = this.options.searchText;
    }
    if (this.options.selectedItems) {
      this.initSelectedItems = this.options.selectedItems;
    } else {
      this.initSelectedItems = new Set() as any;
    }
    this.initSearchText = this.options.searchText;
    this.listOptions = [];
    for (const item of this.options.items.values()) {
      this.listOptions.push({
        id: item.value,
        value: item.name,
        checked: this.options.selectedItems
          && typeof item.value === typeof this.options.selectedItems.values().next().value
          && (this.options.selectedItems as Set<unknown>).has(item.value)
      });
    }
    this.listOptions.sort((a, b) => a.value.localeCompare(b.value));
    this.updateCheckedCount();
    this.spinner.stop();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      this.listOptions = [];
      for (const item of this.options.items.values()) {
        this.listOptions.push({
          id: item.value,
          value: item.name,
          checked: false
        });
      }
      this.listOptions.sort((a, b) => a.value.localeCompare(b.value));
    }
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  // Clear inputs
  clear() {
    this.searchText = '';
    for (let i = 0; i < this.listOptions.length; i++) {
      this.listOptions[i].checked = false;
    }
    this.updateCheckedCount();
  }

  // Close modal and return search text and selected items, if any
  closeModal(cancelled = false) {
    if (cancelled) {
      this.modalInstance.close({
        searchText: this.initSearchText,
        selectedItems: this.initSelectedItems,
        isSelectionChanged: false,
        isSearchChanged: false
      } as MultiCheckSearchResult);
    }
    else {
      const selectedOptions = this.listOptions
        .filter(option => option.checked)
        .map(option => option.id) as string[] | number[];
      this.modalInstance.close({
        searchText: this.searchText,
        selectedItems: selectedOptions?.length > 0 && typeof selectedOptions[0] == 'number' ? new Set<number>(selectedOptions as number[]) : new Set<string>(selectedOptions as string[]),
        isSelectionChanged: selectedOptions.length !== this.initSelectedItems.size
          || !(selectedOptions as unknown[]).every(item => (this.initSelectedItems as Set<unknown>).has(item)),
        isSearchChanged: this.searchText !== this.initSearchText
      } as MultiCheckSearchResult);
    }
  }

  // Method to handle checkbox change event
  onCheckboxChange(): void {
    this.updateCheckedCount();
  }

  updateCheckedCount(): void {
    this.checkedCount = this.listOptions.filter(option => option.checked).length;
  }
}

interface Option {
  id: string | number;
  value: any;
  checked: boolean;
}
