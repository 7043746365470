import { Address, KeyNamePair, NameValuePair, ResourceLocation } from '@app/core/aim';

// Aim producer/agency/retailer (all the same thing in this context)
export interface AimRetailer {
  id: string;
  name: string;
  legalName: string;
  phone: string;
  email: string;
  address: Address;
  mailingAddress: Address;
  accountingAddress: Address;
  parentId?: string;
  status: KeyNamePair;
  isActive: boolean;
  accountingContact: AimRetailerContact;
}

// Retailer Contact
export interface AimRetailerContact {
  id: string;
  name?: string;
  email?: string;
  phone?: string;
  isActive: boolean;
}

// Submission response object - Ported directly from Aim API
export interface AimSubmission {
  id?: string;
  company?: KeyNamePair;
  product?: KeyNamePair;
  coverage?: KeyNamePair;
  marketCompany?: KeyNamePair;
  financeCompany?: KeyNamePair;
  billingType?: string;
  teamId?: string;
  divisionId?: string;
  status?: KeyNamePair;
  stage: AimSubmissionStage;
  policyNumber?: string;
  received?: Date;
  submitted?: Date;
  quoted?: Date;
  bound?: Date;
  isBound: boolean;
  effective?: Date;
  expiration?: Date;
  premium?: number;
  insured: ResourceLocation<string>;
  taxState?: string;
  riskAddress?: Address;
  mailingAddress?: Address;
  accountExecutive: ResourceLocation<number>;
  marketingRep: ResourceLocation<number>;
  producer: ResourceLocation<string>;
  producerContact?: ResourceLocation<number>;
}

// AIM api user response
export interface AimUser {
  id: number;
  division?: AimDivision;
  name?: string;
  teamId: string;
  phone?: string;
  extension?: string;
  email?: string;
  title?: string;
  isActive: boolean;
  isAccountExec: boolean;
  isMarketingRep: boolean;
}

// AIM api division
export interface AimDivision {
  id: string;
  name: string;
  phone?: string;
  address?: Address;
}

// Aim api insured response
export interface AimInsured {
  id: string;
  name: string;
  dbaName?: string;
  phone?: string;
  fax?: string;
  email?: string;
  birthdate?: string;
  ssn?: string;
  address?: Address;
  mailAddress?: Address;
}

// Aim accounting summary
export interface AimAccountingSummary {
  grossPolicyPremium?: number;
  commission?: number;
  netPremium?: number;
  totalNetBilled?: number;
  totalGrossBilled?: number;
  balanceDue?: number;
  totalPremium?: number;
  totalTaxesAndFees?: number;
  totalCharges?: number;
  minimumEarnedPremium?: string;
  taxesAndFees?: NameValuePair<number>[];
  policyAccountingDetail?: AimPolicyAccountingDetail[];
}

export interface AimPolicyAccountingDetail {
  date: Date;
  type: string;
  description: string;
  gross: number;
  charge: number;
  credit: number;
}

// Aim api version response
export interface AimVersion {
  id: string;
  quoteId: string;
  isBound: boolean;
  status: KeyNamePair;
  company?: KeyNamePair;
  product?: KeyNamePair;
  marketCompany?: KeyNamePair;
  submitted?: Date;
  quoted?: Date;
  effective?: Date;
  expiration?: Date;
  premium?: number;
  nonPremium?: number;
  nonTaxPremium?: number;
  tax?: number;
  total?: number;
  coverages?: AimVersionCoverage[];
  taxes?: NameValuePair<number>[];
  fees?: NameValuePair<number>[];
  deductibles?: NameValuePair<string>[];
}

export interface AimVersionCoverage {
  name: string;
  limit: number;
  premium: number;
}

export interface AimDriverSchedule {
  id: number;
  name: string;
  dateOfBirth?: Date;
  gender?: string;
  licenseNumber?: string;
  licenseState?: string;
  added?: Date;
  dropped?: Date;
  isActive: boolean;
}

export interface AimPropertySchedule {
  id: number;
  locationNumber?: number;
  buildingNumber?: number;
  description?: string;
  address?: Address;
  yearBuilt?: number;
  added?: Date;
  dropped?: Date;
  isActive: boolean;
}

export interface AimVehicleSchedule {
  id: number;
  number?: string;
  make?: string;
  model?: string;
  vin?: string;
  year?: number;
  premium?: number;
  collisionDeductible?: string;
  address?: Address;
  description?: string;
  added?: Date;
  dropped?: Date;
  isNew?: boolean;
  isCommercial?: boolean;
  isLeased?: boolean;
  isDelivery?: boolean;
  isRental?: boolean;
  isActive: boolean;
}

// Policy Document
export interface AimPolicyDocument {
  id: string;
  type: string;
  description: string;
  pageCount: number;
  modified: Date;
}

// Open invoice
export interface AimOpenInvoice {
  invoiceId?: string;
  policyNumber?: string;
  insured: ResourceLocation<string>;
  office: ResourceLocation<string>;
  effective?: Date;
  due?: Date;
  balance: number;
}

// Policy Certificate
export interface AimCertificate {
  id: number; // Cert holder ID
  name: string;
  certificateId: number;
  address?: Address;
  type: string;
}

// Submission stage options
export const PolicySubmissionStages: AimSubmissionStage[] = ['Active', 'Inactive', 'Binding'];
export const QuoteSubmissionStages: AimSubmissionStage[] = ['Active', 'Inactive', 'Quoting', 'Renewing'];

// Submission stages
export type AimSubmissionStage = 'Active' | 'Inactive' | 'Quoting' | 'Renewing' | 'Binding';

export type AimNewOrRenewal = 'New Business' | 'Renewal';

// Submission stage mappings
export interface AimSubmissionStageCodes {
  id: string;
  codes: KeyNamePair[];
}

// Certificate Holder
export interface AimCertificateHolder {
  quoteId: string;
  name: string;
  type: string;
  address: Address;
  phone?: string;
  fax?: string;
  isAdditionalInsured?: boolean;
  additionalInsured?: string;
  otherDesc?: string;
  reasonRequest?: string;
  wording?: string;
}

// Certificate modal return result
export interface AimCertificateCreationResponse {
  type: AimCertificateCreationResult;
  message: string;
}

// Certificate modal return type
export enum AimCertificateCreationResult {
  Created,
  Accepted,
  Error
}

// Renewal
export interface AimRenewal {
  id: string;
  firstEffective?: Date;
  currentCount: number;
  totalCount: number;
  history: AimRenewalHistory[];
}

// Renewal History
export interface AimRenewalHistory {
  id: string;
  policyNumber?: string;
  isBound: boolean;
  accountExecutiveId: number;
  accountExecutiveName?: string;
  effective?: Date;
  expiration?: Date;
}

export interface AimTeam {
  id: string;
  name: string;
  divisionId: string;
}

export enum ExportType {
  Retailer = 'Retailer',
  Producer = 'Producer'
}