import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import {
  Observable,
  Subject,
  Subscription,
  filter,
  firstValueFrom,
  switchMap,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { Debug } from './debug';
import { AuthHttpInterceptor } from './profile';

@Injectable({
  providedIn: 'root'
})
export class AutoCancelHttpService implements OnDestroy {
  private triggerCancellation = new Subject<void>();
  private sub: Subscription;
  private cancelableList = new Map<string, Subject<void>>();

  // Will auto-trigger upon page change for components which don't use the provided get/post methods
  navigationDetected$ = this.triggerCancellation.asObservable();

  constructor(
    private readonly router: Router,
    private readonly http: HttpClient
  ) {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationStart)
    ).subscribe(() => {
      this.triggerCancellation.next();
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    this.cancelableList.forEach((v, k) => {
      this.triggerCancel(k);
    });
  }

  /**
 * Similar to HttpService get method, except it will auto-cancel upon page change
 * And cancel duplicate calls to the same endpoint if call is marked as force distinct
 * @template T
 * @param url string
 * @param [enableAuth] boolean (true)
 * @param [forceDistinct] boolean (false)
 * @param [caching] boolean (true)
 * @param [loadingSubject] Subject<boolean> (null)
 * @returns
 */
  get<T>(url: string, enableAuth = true, forceDistinct = false, caching = true, loadingSubject: Subject<boolean> = null): Promise<T> {
    return firstValueFrom(this.cancelableGet(url, enableAuth, forceDistinct, caching, loadingSubject), { defaultValue: undefined });
  }

  autoRefresh<T>(url: string, enableAuth = true, forceDistinct = false, caching = true, loadingSubject: Subject<boolean> = null, interval = 10000): Observable<T> {
    return this.cancelableGet(url, enableAuth, forceDistinct, caching, loadingSubject, interval);
  }

  /**
   * Similar to HttpService post method, except it will auto-cancel upon page change
   * @template T
   * @param url string
   * @param body any
   * @param [enableAuth] boolean (true)
   * @returns
   */
  post<T>(url: string, body: any, enableAuth = true): Promise<T> {
    const post = this.http.post<T>(
      url,
      body,
      (enableAuth) ? AuthHttpInterceptor.AddAuthHttpHeader : {}
    ).pipe(takeUntil(this.navigationDetected$));
    return firstValueFrom(post, { defaultValue: undefined });
  }

  private cancelableGet<T>(url: string, enableAuth: boolean, forceDistinct = false, caching = true, loadingSubject: Subject<boolean> = null, interval = -1) {
    const position = url.indexOf('?');
    const endPoint = position === -1 ? url : url.substring(0, position);
    const processCancel$ = new Subject<void>();
    if (forceDistinct) {
      if (this.cancelableList.has(endPoint)) {
        Debug.log(`Cancelling the previous call to the same endpoint (${endPoint}) call`);
        this.triggerCancel(endPoint);
      }
      this.cancelableList.set(endPoint, processCancel$);
    }

    const headers = enableAuth
      ? caching
        ? AuthHttpInterceptor.AddAuthHttpHeader
        : AuthHttpInterceptor.AuthHttpHeaderNoCache
      : caching
        ? {}
        : {
          headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
          }
        };
    let get$ = timer(0, interval).pipe(
      switchMap(() => {
        loadingSubject?.next(true);
        return this.http.get<T>(
          url,
          headers
        );
      }),
      takeUntil(this.navigationDetected$)
    );

    if (forceDistinct) {
      get$ = get$.pipe(takeUntil(processCancel$));
    }

    return get$.pipe(
      tap(() => {
        loadingSubject?.next(false);
        const found = this.cancelableList.get(endPoint);
        if (found) { // Check if it is not deleted yet
          found.complete();
          this.cancelableList.delete(endPoint);
        }
      })
    );
  }

  private triggerCancel(endPoint: string) {
    const found = this.cancelableList.get(endPoint);
    if (found) { // Check if it is not deleted yet
      found.next();
      found.complete();
      this.cancelableList.delete(endPoint);
    }
  }
}