import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from "@angular/router";
import { Debug, ModalSpinnerService, NotificationService, ProfileService } from "@app/core";
import { NiprArea } from "@app/management/nipr/core/nipr-areas";
import {
  BatchProducer,
  BatchProducerState,
  BatchReportState,
  BatchReportViewModel,
  DetailsType,
  NiprDetailsOptions
} from "@app/management/nipr/core/nipr.interfaces";
import { NiprService } from "@app/management/nipr/core/nipr.service";
import { CreateBatchComponent } from "@app/management/nipr/create-batch/create-batch.component";
import { NiprDetailsComponent } from "@app/management/nipr/nipr-details/nipr-details.component";
import { ApiErrorService } from "@core/api-error.service";
import { ClientPagingService } from "@core/client-paging/client-paging.service";
import { SessionService } from "@core/storage/session.service";
import { WindowVisibilityService } from "@core/window-visibility.service";
import { filter, Subject, Subscription } from "rxjs";
import { v4 as uuidv4 } from 'uuid';


@Component({
  selector: 'batch-reports',
  templateUrl: './batch-reports.component.html',
  styleUrl: './batch-reports.component.scss'
})
export class BatchReportsComponent implements OnInit, OnDestroy {
  protected autoRefresh: boolean;
  protected displayBatchReports: BatchReportViewModel[]; // displayed batches in current page
  protected batchCount: number;
  protected isLoading: boolean;
  protected BatchReportState = BatchReportState;
  protected DetailsType = DetailsType;

  private customSessionPrefix: string;
  private sessionId: string;
  private isInitialized: boolean;
  private isActiveRoute: boolean;
  private isWindowVisible: boolean;
  private isDetailsModalOpen: boolean;

  private batchReportsSub: Subscription;
  private visibilitySub: Subscription;
  private loadingSub: Subscription;
  private routeSub: Subscription;
  private activeSub: Subscription;

  private activeSubject: Subject<void>;

  private _page: number;
  private _pageSize: number;
  private _maxSize: number; // pager size
  private _screenWidth: number;
  private _batchReports: BatchReportViewModel[]; // all batches from server

  private readonly id: string;

  @ViewChild('batchReports') table: ElementRef<HTMLTableElement>;

  constructor(
    private readonly session: SessionService,
    private readonly profile: ProfileService,
    private readonly nipr: NiprService,
    private readonly window: Window,
    private readonly clientPaging: ClientPagingService,
    private readonly notify: NotificationService,
    private readonly error: ApiErrorService,
    private readonly router: Router,
    private readonly spinner: ModalSpinnerService,
    windowVisibility: WindowVisibilityService
  ) {
    this.isInitialized = false;
    this.id = uuidv4();
    this.isActiveRoute = true;
    this.isWindowVisible = true;
    this.isDetailsModalOpen = false;
    this.activeSubject = new Subject<void>();
    this._page = 1;
    this._pageSize = 10;
    this._screenWidth = window.innerWidth;
    this._maxSize = this._screenWidth < 494 ? 3 : this._screenWidth < 687 ? 7 : 12;
    this.autoRefresh = true;
    this.customSessionPrefix = '';
    this.sessionId = '';
    this.displayBatchReports = [];
    this.batchCount = 0;
    this._batchReports = [];
    this.activeSub = this.activeSubject.subscribe(async () => {
      if (this.isInitialized && !this.isDetailsModalOpen) {
        if (this.autoRefresh) {
          if (this.isWindowVisible && this.isActiveRoute) {
            Debug.debug(`Batch Reports (${this.id}) active`);
            this.subscribeToBatchReports();
          } else {
            Debug.debug(`Batch Reports (${this.id}) inactive`);
            this.unsubscribeFromBatchReports();
          }
        } else if (this.isWindowVisible && this.isActiveRoute) {
          Debug.debug(`Batch Reports (${this.id}) active`);
          await this.getBatchReports();
        } else {
          Debug.debug(`Batch Reports (${this.id}) inactive`);
        }
      }
    });
    this.visibilitySub = windowVisibility.getVisibility().subscribe((isVisible) => {
      if (this.isWindowVisible !== isVisible) {
        this.isWindowVisible = isVisible;
        this.activeSubject.next();
      }
    });
    this.loadingSub = this.nipr.loadingBatchReports.subscribe(loading => this.isLoading = loading);
    this.routeSub = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const parts = this.router.url.split('/');
      const lastPart = parts ? parts[parts.length - 1] : '';
      if (lastPart && lastPart === NiprArea.BatchReports) {
        if (!this.isActiveRoute) {
          this.isActiveRoute = true;
          this.activeSubject.next();
        }
      } else {
        if (this.isActiveRoute) {
          this.isActiveRoute = false;
          this.activeSubject.next();
        }
      }
    });
  }

  async ngOnInit() {
    await this.loadSessionPrefix();
    this.loadPaging();
    this.loadAutoRefresh();
    if (this.autoRefresh) {
      this.subscribeToBatchReports();
    } else {
      await this.getBatchReports();
      this.isInitialized = true;
    }
    Debug.debug(`Batch Reports (${this.id}) created`);
  }

  ngOnDestroy() {
    this.batchReportsSub?.unsubscribe();
    this.visibilitySub?.unsubscribe();
    this.loadingSub?.unsubscribe();
    this.routeSub?.unsubscribe();
    this.activeSub?.unsubscribe();
    Debug.debug(`Batch Reports (${this.id}) destroyed`);
  }

  protected get page() {
    return this._page;
  }

  protected set page(page: number) {
    if (page !== this.page) {
      this._page = page;
      this.savePaging();
      this.updateDisplayData();
      if (this.pageSize > 10) {
        this.scrollToTop();
      }
    }
  }

  protected get pageSize() {
    return this._pageSize;
  }

  protected set pageSize(pageSize: number) {
    if (pageSize !== this.pageSize) {
      this._pageSize = pageSize;
      this.savePaging();
      this.updateDisplayData();
      if (this.batchCount > pageSize) {
        this.scrollToTop();
      }
    }
  }

  protected get maxSize() {
    return this._maxSize;
  }

  protected get smallDevice() {
    return this._screenWidth <= 767;
  }

  // Detect screen size change - currently used for adjusting paging maxSize.
  @HostListener('window:resize', ['$event'])
  protected onWindowResize() {
    this._screenWidth = window.innerWidth;
    this._maxSize = this._screenWidth < 494 ? 3 : this._screenWidth < 687 ? 7 : 12;
  }

  protected get showScrollButton() {
    return this.smallDevice || this.pageSize >= 25;
  }

  protected scrollToTop(allTheWayUp = false) {
    if (allTheWayUp) {
      this.window.scrollTo(0, 0);
    } else {
      this.table.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  protected async toggleAutoRefresh(event: Event) {
    if (this.autoRefresh) {
      this.unsubscribeFromBatchReports();
    } else {
      this.subscribeToBatchReports();
    }
    this.autoRefresh = (event.target as HTMLInputElement).checked;
    this.saveAutoRefresh();
  }

  protected get isDataReady() {
    return this.isInitialized && this.batchCount > 0;
  }

  protected get showLoadingIndicator() {
    return !this.isInitialized && this.isLoading;
  }

  // Determines batch singular/plural text
  protected getBatchNumberText(): string {
    return (this.batchCount > 1) ? 'batches' : 'batch';
  }

  protected async openDetailsModal(report: BatchReportViewModel, type: DetailsType) {
    try {
      this.spinner.start();
      this.isDetailsModalOpen = true;
      if (this.autoRefresh) {
        this.unsubscribeFromBatchReports();
      }
      let licenseUpdates = await this.nipr.getBatchReportLicenseUpdates(report.id);
      if (type === DetailsType.Notified) {
        licenseUpdates = licenseUpdates.filter(license => license.notifyFlag === true);
      } else {
        licenseUpdates = licenseUpdates.filter(license => license.updateStateString.toLowerCase() === type.toString());
      }
      let producers: BatchProducer[] = null;
      if ([DetailsType.Open, DetailsType.Imported, DetailsType.Errored].includes(type)) {
        const batchReport = await this.nipr.getProducersBatchReport(report.id);
        if (type === DetailsType.Errored) {
          producers = batchReport.producers.filter(producer => producer.state.toLowerCase() === BatchProducerState.Errored.toString());
        } else if (type === DetailsType.Open) {
          producers = batchReport.producers.filter(producer => producer.state.toLowerCase() === BatchProducerState.Open.toString() || producer.state === BatchProducerState.Parsed.toString());
        } else if (type === DetailsType.Imported) {
          producers = batchReport.producers.filter(producer => producer.state.toLowerCase() === BatchProducerState.Processed.toString());
        }
      }
      let totalText: string;
      switch (type) {
        case DetailsType.Open:
          totalText = `${report.openProducerCount + report.parsedProducerCount} / ${report.openProducerCount + report.parsedProducerCount + report.processedProducerCount + report.erroredProducerCount} (${report.openLicenseUpdateCount})`;
          break;
        case DetailsType.Imported:
          totalText = `${report.processedProducerCount} / ${report.openProducerCount + report.parsedProducerCount + report.processedProducerCount + report.erroredProducerCount} (${report.importedLicenseUpdateCount})`;
          break;
        case DetailsType.Notified:
          totalText = report.notifyLicenseUpdateCount.toString();
          break;
        case DetailsType.Errored:
          totalText = `${report.erroredProducerCount} / ${report.openProducerCount + report.parsedProducerCount + report.processedProducerCount + report.erroredProducerCount} (${report.erroredLicenseUpdateCount})`;
          break;
        case DetailsType.Ignored:
          totalText = report.ignoredLicenseUpdateCount.toString();
          break;
      }
      const options = {
        reportType: 'BatchReport',
        data: report,
        type: type,
        licenseUpdates: licenseUpdates,
        batchProducers: producers,
        totalItemsText: totalText
      } as NiprDetailsOptions;
      await this.notify.showModalTemplate(NiprDetailsComponent, { options: options }, { size: 'xl', windowClass: 'overflow-hidden', backdropClass: 'w-100 h-100' });
    } catch (e) {
      if (!e['dismissed']) {
        this.error.processError(e, 'openDetailsModal');
      }
    } finally {
      this.isDetailsModalOpen = false;
      if (this.autoRefresh) {
        this.subscribeToBatchReports();
      } else {
        await this.getBatchReports();
      }
      this.spinner.stop();
    }
  }

  protected async createNewBatch() {
    try {
      if (this.autoRefresh) {
        this.unsubscribeFromBatchReports();
      }
      await this.notify.showModalTemplate(CreateBatchComponent, null, { backdropClass: 'w-100 h-100' });
    } catch (e) {
      if (!e['dismissed']) {
        this.error.processError(e, 'createNewBatchModal');
      }
    } finally {
      if (this.autoRefresh) {
        this.subscribeToBatchReports();
      } else {
        await this.getBatchReports();
      }
    }
  }

  private async getBatchReports() {
    this.isLoading = true;
    const reports = await this.nipr.getBatchReports();
    if (reports !== undefined) {
      this.refreshData(reports);
    }
    this.isLoading = false;
  }

  private refreshData(reports: BatchReportViewModel[]) {
    this._batchReports = this.sortBatchReports(reports);
    this.updateDisplayData();
  }

  private sortBatchReports(batchReports: BatchReportViewModel[]) {
    return batchReports.sort((a, b) => b.id - a.id);
  }

  private updateDisplayData() {
    const pagingResult = this.clientPaging.getDisplayData(this._batchReports, this.displayBatchReports, this.pageSize, this.page);
    if (pagingResult !== null) {
      this.displayBatchReports = pagingResult.data;
      if (pagingResult.pageNumber !== this.page) {
        this._page = pagingResult.pageNumber;
      }
    }
    this.batchCount = this._batchReports?.length || 0;
  }

  private async loadSessionPrefix() {
    const profileData = await this.profile.getProfile();
    this.sessionId = profileData.id?.toString();
    this.customSessionPrefix = `portal.${profileData.id}.management.nipr.batch-reports`;
  }

  private loadPaging() {
    this._page = this.session.getItem<number>('page', 1, false, this.customSessionPrefix);
    this._pageSize = this.session.getItem<number>('pageSize', 10, false, this.customSessionPrefix);
  }

  private savePaging() {
    this.session.setItem('page', this.page, false, this.customSessionPrefix);
    this.session.setItem('pageSize', this.pageSize, false, this.customSessionPrefix);
  }

  private loadAutoRefresh() {
    const savedSessionId = this.session.getItem<string>('sessionSaved', null, false, this.customSessionPrefix);
    if (savedSessionId == this.sessionId) {
      this.autoRefresh = this.session.getItem('autoRefresh', true, false, this.customSessionPrefix);
    } else {
      this.session.setItem('sessionSaved', this.sessionId, false, this.customSessionPrefix);
      this.saveAutoRefresh();
    }
  }

  private saveAutoRefresh() {
    this.session.setItem('autoRefresh', this.autoRefresh, false, this.customSessionPrefix);
  }

  private subscribeToBatchReports() {
    this.batchReportsSub = this.nipr.autoRefreshBatchReports().subscribe((batchReports) => {
      this.refreshData(batchReports);
      this.isInitialized = true;
    });
  }

  private unsubscribeFromBatchReports() {
    this.batchReportsSub?.unsubscribe();
    this.batchReportsSub = null;
  }
}
