import { ActivatedRouteSnapshot, BaseRouteReuseStrategy, DetachedRouteHandle } from "@angular/router";

// Strategy to reuse routes based on components. See below for usage.
// Routing RouteReuseStrategy provided in a lazy loaded module doesn't seem to work.
// {
//   provide: RouteReuseStrategy,
//   useFactory: () => new ComponentRouteReuseStrategy([SweepHistoryComponent, BatchReportsComponent])
// }
export class ComponentRouteReuseStrategy extends BaseRouteReuseStrategy {
  private componentsToReuse: any[];
  private storedRoutes: Map<string, DetachedRouteHandle>;

  constructor(componentsToReuse: any[]) {
    super();
    this.componentsToReuse = componentsToReuse;
    this.storedRoutes = new Map<string, DetachedRouteHandle>();
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return this.componentsToReuse.includes(route.component);
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    const path = this.getRoutePath(route);
    this.storedRoutes.set(path, handle);
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const path = this.getRoutePath(route);
    return this.storedRoutes.has(path) && this.componentsToReuse.includes(route.component);
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    const path = this.getRoutePath(route);
    return this.storedRoutes.get(path) || null;
  }

  private getRoutePath(route: ActivatedRouteSnapshot): string {
    return route.routeConfig ? route.routeConfig.path : '';
  }
}
