import { PlatformLocation } from "@angular/common";
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { ModalSpinnerService, NotificationService, ToastType } from "@app/core";
import { CreateBatchReport } from "@app/management/nipr/core/nipr.interfaces";
import { NiprService } from "@app/management/nipr/core/nipr.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'create-batch',
  templateUrl: './create-batch.component.html',
  styleUrl: './create-batch.component.scss'
})
export class CreateBatchComponent implements OnInit {

  protected form: FormGroup;
  protected validatorsCleared: boolean;

  private _validators: Map<string, ValidatorFn>;

  constructor(
    private readonly modalInstance: NgbActiveModal,
    private readonly notify: NotificationService,
    private readonly nipr: NiprService,
    private readonly formBuilder: FormBuilder,
    private readonly spinner: ModalSpinnerService,
    private readonly platformLocation: PlatformLocation
  ) {
    this.validatorsCleared = true;
  }

  ngOnInit() {
    this.form = this.createForm();
    this.platformLocation.onPopState(() => this.cancel());
  }

  async submitForm() {
    const requestBody = this.createRequestFromForm();
    if (!requestBody) {
      this.notify.showToast('No form data to submit', 'Error', ToastType.Error);
      return;
    }
    this.spinner.start();
    try {
      await this.nipr.createBatchReport(requestBody);
      this.notify.showToast('Successfully created new batch', 'Success', ToastType.Success);
    } catch {
      this.notify.showToast('Failed to create new batch', 'Error', ToastType.Error);
    } finally {
      this.spinner.stop();
    }
    this.modalInstance.close();
  }

  cancel() {
    this.modalInstance.dismiss({ dismissed: true });
  }

  clearValidators(controlName: string) {
    if (this._validators.get(controlName) === undefined) {
      const existingValidator = this.form.get(controlName)?.validator;
      if (existingValidator) {
        this._validators.set(controlName, existingValidator);
      }
    }
    const control = this.form.get(controlName);
    if (control.validator) {
      control.clearValidators();
      this.validatorsCleared = true;
      control.updateValueAndValidity();
    }
  }

  validate(controlName: string) {
    const validator = this._validators.get(controlName);
    if (validator) {
      this.form.get(controlName).setValidators(validator);
      this.validatorsCleared = false;
      this.form.get(controlName).updateValueAndValidity();
    }
  }

  private createRequestFromForm(): CreateBatchReport | null {
    if (!this.form.valid) {
      return null;
    }
    return {
      description: this.form.value?.description,
      producers: this.form.value?.producers.split(/[,\t]+|\r?\n+/).filter((item: string) => item !== '')
    } as CreateBatchReport;
  }

  private createForm() {
    this._validators = new Map<string, ValidatorFn>();
    let controls = {
      description: [
        null,
        []
      ],
      producers: [
        null,
        [Validators.required, Validators.pattern(/^[a-zA-Z0-9]{8}(?:[,\t\r\n]+[a-zA-Z0-9]{8})*$/)]
      ]
    };
    return this.formBuilder.group(controls);
  }
}
