// Alert Reports
export interface AlertReport {
  id: number;
  state: AlertReportState;
  processingError: string;
  date: string;
  openLicenseUpdateCount: number;
  ignoredLicenseUpdateCount: number;
  erroredLicenseUpdateCount: number;
  importedLicenseUpdateCount: number;
  notifyLicenseUpdateCount: number;
}

export enum AlertReportState {
  Open = "open",
  Downloaded = "downloaded",
  Parsed = "parsed",
  Processed = "processed",
  Errored = "errored"
}

// License Updates
export enum LicenseType {
  Agency,
  Individual
}

export enum UpdateState {
  Open,
  Ignored,
  Errored,
  Imported
}

export interface LicenseUpdate {
  id: number;
  npn: string;
  producerId: string;
  licenseNumber: string;
  state: string;
  statusCode: string;
  classCode: string;
  classDescription: string;
  licenseEndDate?: Date;
  updateState: UpdateState;
  updateStateString: string;
  comment: string;
  alertReportId?: number;
  alertReportDate: Date;
  batchReportId?: number;
  notes: string;
  updateStateTimestamp: Date;
  licenseType: LicenseType;
  licenseTypeString: string;
  notifyFlag: boolean;
}

// Batch Reports
export enum BatchReportState {
  Open = "open",
  Processed = "processed",
  Errored = "errored"
}

export interface BatchReportViewModel {
  id: number;
  timeStamp: Date;
  description: string;
  state: BatchReportState,
  openProducerCount: number;
  erroredProducerCount: number;
  parsedProducerCount: number;
  processedProducerCount: number;
  openLicenseUpdateCount: number;
  ignoredLicenseUpdateCount: number;
  erroredLicenseUpdateCount: number;
  importedLicenseUpdateCount: number;
  notifyLicenseUpdateCount: number;
}

export interface BatchReport {
  id: number;
  timeStamp: Date;
  description: string;
  state: BatchReportState;
  producers: BatchProducer[];
}

export interface CreateBatchReport {
  producers: string[],
  description: string;
}

export enum BatchProducerState {
  Open = "open",
  Parsed = "parsed",
  Processed = "processed",
  Errored = "errored"
}

export interface BatchProducer {
  id: number;
  state: BatchProducerState;
  batchReportId: number;
  producerId: string;
  notes: string;
}

// Details Modal
export enum DetailsType {
  Open = "open",
  Imported = "imported",
  Notified = "notified",
  Errored = "errored",
  Ignored = "ignored"
}

export interface NiprDetailsOptions {
  data: AlertReport | BatchReportViewModel;
  reportType: 'AlertReport' | 'BatchReport';
  type: DetailsType;
  licenseUpdates: LicenseUpdate[];
  batchProducers: BatchProducer[] | null;
  totalItemsText: string;
}