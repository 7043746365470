<div class="container-fluid">
  <div class="row content mx-auto">
    <div class="col px-0">
      <div class="page-header-container form-section d-flex justify-content-between align-items-center w-100 mb-3">
        <button class="btn btn-primary ms-3" type="button" (click)="createNewBatch()">Create New Batch</button>
        <label class="form-check-label me-3">
          <input type="checkbox" class="form-check-input" [checked]="autoRefresh" (change)="toggleAutoRefresh($event)">
          Automatic refresh
        </label>
      </div>
    </div>
    <div class="scroll mb-3">
      <table class="table table-striped outer-borders" #batchReports>
        <thead class="thead-medium">
          <tr>
            <th scope="col">Description</th>
            <th scope="col">State</th>
            <th scope="col">Open</th>
            <th scope="col">Imported</th>
            <th scope="col">Notified</th>
            <th scope="col">Errored</th>
            <th scope="col">Ignored</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="isDataReady; else loadingTemplate">
            <tr *ngFor="let batchReport of displayBatchReports">
              <td data-qa data-th="Description" class="text-nowrap">{{ batchReport.description }} -
                {{ batchReport.timeStamp | date:'M/d' }}</td>
              <td data-qa data-th="State">{{ batchReport.state }}</td>
              <td data-qa data-th="Open"><button type="button"
                        class="btn btn-nipr btn-success d-block w-100 d-inline-flex align-items-center justify-content-center gap-3"
                        [disabled]="batchReport.openLicenseUpdateCount + batchReport.openProducerCount + batchReport.parsedProducerCount === 0"
                        (click)="openDetailsModal(batchReport, DetailsType.Open)">Open <span
                        class="badge bg-light text-dark">{{ batchReport.openProducerCount + batchReport.parsedProducerCount }}
                    /
                    {{ batchReport.openProducerCount + batchReport.parsedProducerCount + batchReport.processedProducerCount + batchReport.erroredProducerCount }}
                    ({{ batchReport.openLicenseUpdateCount }})</span></button></td>
              <td data-qa data-th="Imported"><button type="button"
                        class="btn btn-nipr btn-success d-block w-100 d-inline-flex align-items-center justify-content-center gap-3"
                        [disabled]="batchReport.importedLicenseUpdateCount + batchReport.processedProducerCount === 0"
                        (click)="openDetailsModal(batchReport, DetailsType.Imported)">Imported <span
                        class="badge bg-light text-dark">{{ batchReport.processedProducerCount }} /
                    {{ batchReport.openProducerCount + batchReport.parsedProducerCount + batchReport.processedProducerCount + batchReport.erroredProducerCount }}
                    ({{ batchReport.importedLicenseUpdateCount }})</span></button></td>
              <td data-qa data-th="Notified"><button type="button"
                        class="btn btn-nipr btn-warning d-block w-100 d-inline-flex align-items-center justify-content-center gap-3"
                        [disabled]="batchReport.notifyLicenseUpdateCount === 0"
                        (click)="openDetailsModal(batchReport, DetailsType.Notified)">Notified <span
                        class="badge bg-light text-dark">{{ batchReport.notifyLicenseUpdateCount }}</span></button></td>
              <td data-qa data-th="Errored"><button type="button"
                        class="btn btn-nipr btn-danger d-block w-100 d-inline-flex align-items-center justify-content-center gap-3"
                        [disabled]="batchReport.erroredLicenseUpdateCount + batchReport.erroredProducerCount === 0"
                        (click)="openDetailsModal(batchReport, DetailsType.Errored)">Errored <span
                        class="badge bg-light text-dark">{{ batchReport.erroredProducerCount }} /
                    {{ batchReport.openProducerCount + batchReport.parsedProducerCount + batchReport.processedProducerCount + batchReport.erroredProducerCount }}
                    ({{ batchReport.erroredLicenseUpdateCount }})</span></button></td>
              <td data-qa data-th="Ignored"><button type="button"
                        class="btn btn-nipr btn-grayed d-block w-100 d-inline-flex align-items-center justify-content-center gap-3"
                        [disabled]="batchReport.ignoredLicenseUpdateCount === 0"
                        (click)="openDetailsModal(batchReport, DetailsType.Ignored)">Ignored <span
                        class="badge bg-light text-dark">{{ batchReport.ignoredLicenseUpdateCount }}</span></button>
              </td>
            </tr>
          </ng-container>
          <ng-template #loadingTemplate>
            <tr [class.loading]="showLoadingIndicator">
              <td class="no-results center" colspan="7">
                <h4 class="m-2 ms-0 text line w-100">No results found</h4>
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>
    <div *ngIf="isDataReady" class="table-footer hstack mb-3">
      <ngb-pagination *ngIf="batchCount > pageSize" class="mb-2 mb-sm-0" [collectionSize]="batchCount" [(page)]="page"
                      [pageSize]="pageSize" [maxSize]="maxSize"></ngb-pagination>
      <span class="mb-2 mb-sm-auto" *ngIf="batchCount">{{batchCount | number}}
        {{ getBatchNumberText() }} total</span>
      <select class="mb-2 mb-sm-0 form-select page-size" title="Set items per page" name="pageSize"
              [(ngModel)]="pageSize">
        <option [ngValue]="10">10 items per page</option>
        <option [ngValue]="25">25 items per page</option>
        <option [ngValue]="50">50 items per page</option>
        <option [ngValue]="100">100 items per page</option>
        <option [ngValue]="200">200 items per page</option>
        <option [ngValue]="500">500 items per page</option>
        <option [ngValue]="1000">1,000 items per page</option>
      </select>
      <button *ngIf="showScrollButton" type="button" class="mb-2 mb-sm-0 btn btn-primary" (click)="scrollToTop(true)">
        Back to top
      </button>
    </div>
  </div>
</div>