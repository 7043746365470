import { inject } from "@angular/core";
import { CanActivateChildFn, CanActivateFn } from "@angular/router";
import { HomeRedirectService } from "./home-redirect.service";

export const WelcomeGuard: CanActivateFn | CanActivateChildFn = async (route, state) => {
  const homeRedirect = inject(HomeRedirectService);

  const profile = await homeRedirect.activeUser();
  if (!profile.isAuthenticated) {
    return true;
  }

  if (homeRedirect.isLocalhost()) {
    return true;
  }

  await homeRedirect.redirect(profile);

  return false;
};