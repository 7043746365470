<div class="container-fluid">
  <div class="row content mx-auto">
    <div class="col px-0">
      <div class="page-header-container form-section d-flex justify-content-end align-items-center w-100 mb-3">
        <label class="form-check-label me-3">
          <input type="checkbox" class="form-check-input" [checked]="autoRefresh" (change)="toggleAutoRefresh($event)">
          Automatic refresh
        </label>
      </div>
    </div>
    <div class="scroll mb-3">
      <table class="table table-striped outer-borders" #sweepHistory>
        <thead class="thead-medium">
          <tr>
            <th scope="col">Date</th>
            <th scope="col">State</th>
            <th scope="col">Open</th>
            <th scope="col">Imported</th>
            <th scope="col">Notified</th>
            <th scope="col">Errored</th>
            <th scope="col">Ignored</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="isDataReady; else loadingTemplate">
            <tr *ngFor="let alertReport of displayAlertReports">
              <td data-qa data-th="Date" class="text-nowrap">{{ alertReport.date }}</td>
              <td data-qa data-th="State">{{ alertReport.state }}</td>
              <ng-container *ngIf="!alertReport.processingError; else rowErrorTemplate">
                <td data-qa data-th="Open"><button type="button"
                          class="btn btn-nipr btn-success d-block w-100 d-inline-flex align-items-center justify-content-center gap-3"
                          [disabled]="alertReport.openLicenseUpdateCount === 0 && alertReport.state !== AlertReportState.Downloaded"
                          (click)="openDetailsModal(alertReport, DetailsType.Open)">Open <span
                          class="badge bg-light text-dark">{{ alertReport.openLicenseUpdateCount }}</span></button></td>
                <td data-qa data-th="Imported"><button type="button"
                          class="btn btn-nipr btn-success d-block w-100 d-inline-flex align-items-center justify-content-center gap-3"
                          [disabled]="alertReport.importedLicenseUpdateCount === 0 && alertReport.state !== AlertReportState.Downloaded"
                          (click)="openDetailsModal(alertReport, DetailsType.Imported)">Imported <span
                          class="badge bg-light text-dark">{{ alertReport.importedLicenseUpdateCount }}</span></button>
                </td>
                <td data-qa data-th="Notified"><button type="button"
                          class="btn btn-nipr btn-warning d-block w-100 d-inline-flex align-items-center justify-content-center gap-3"
                          [disabled]="alertReport.notifyLicenseUpdateCount === 0 && alertReport.state !== AlertReportState.Downloaded"
                          (click)="openDetailsModal(alertReport, DetailsType.Notified)">Notified <span
                          class="badge bg-light text-dark">{{ alertReport.notifyLicenseUpdateCount }}</span></button>
                </td>
                <td data-qa data-th="Errored"><button type="button"
                          class="btn btn-nipr btn-danger d-block w-100 d-inline-flex align-items-center justify-content-center gap-3"
                          [disabled]="alertReport.erroredLicenseUpdateCount === 0 && alertReport.state !== AlertReportState.Downloaded"
                          (click)="openDetailsModal(alertReport, DetailsType.Errored)">Errored <span
                          class="badge bg-light text-dark">{{ alertReport.erroredLicenseUpdateCount }}</span></button>
                </td>
                <td data-qa data-th="Ignored"><button type="button"
                          class="btn btn-nipr btn-grayed d-block w-100 d-inline-flex align-items-center justify-content-center gap-3"
                          [disabled]="alertReport.ignoredLicenseUpdateCount === 0 && alertReport.state !== AlertReportState.Downloaded"
                          (click)="openDetailsModal(alertReport, DetailsType.Ignored)">Ignored <span
                          class="badge bg-light text-dark">{{ alertReport.ignoredLicenseUpdateCount }}</span></button>
                </td>
              </ng-container>
              <ng-template #rowErrorTemplate>
                <td data-qa data-th="Error" colspan="5">
                  <h4 class="d-flex align-items-center justify-content-center mb-0"><span
                          class="error-message badge bg-danger d-block w-100 text-start text-wrap">{{ alertReport.processingError }}<button
                              type="button" class="btn ms-1" [class.pe-none]="hasRetry(alertReport.id)"
                              (click)="retry(alertReport)"><object class="retry"
                                [class.rotate]="hasRetry(alertReport.id)" data="assets/images/arrows-rotate-solid.svg"
                                type="image/svg+xml" width="16px" height="16px"
                                title="refresh"></object></button></span></h4>
                </td>
              </ng-template>
            </tr>
          </ng-container>
          <ng-template #loadingTemplate>
            <tr [class.loading]="showLoadingIndicator">
              <td class="no-results center" colspan="7">
                <h4 class="m-2 ms-0 text line w-100">No results found</h4>
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>
    <div *ngIf="isDataReady" class="table-footer hstack mb-3">
      <ngb-pagination *ngIf="alertCount > pageSize" class="mb-2 mb-sm-0" [collectionSize]="alertCount" [(page)]="page"
                      [pageSize]="pageSize" [maxSize]="maxSize"></ngb-pagination>
      <span class="mb-2 mb-sm-auto" *ngIf="alertCount">{{alertCount | number}}
        {{ getAlertNumberText() }} total</span>
      <select class="mb-2 mb-sm-0 form-select page-size" title="Set items per page" name="pageSize"
              [(ngModel)]="pageSize">
        <option [ngValue]="10">10 items per page</option>
        <option [ngValue]="25">25 items per page</option>
        <option [ngValue]="50">50 items per page</option>
        <option [ngValue]="100">100 items per page</option>
        <option [ngValue]="200">200 items per page</option>
        <option [ngValue]="500">500 items per page</option>
        <option [ngValue]="1000">1,000 items per page</option>
      </select>
      <button *ngIf="showScrollButton" type="button" class="mb-2 mb-sm-0 btn btn-primary" (click)="scrollToTop(true)">
        Back to top
      </button>
    </div>
  </div>
</div>