<div class="d-grid d-md-flex justify-content-around mb-3 table-footer">
  <ngb-pagination *ngIf="total > 10" class="mb-2 mb-md-0" [collectionSize]="total" [page]="page"
                  (pageChange)="setPage($event)" [pageSize]="pageSize" [maxSize]="12"></ngb-pagination>
  <div class="mb-2 mx-2 mb-md-0 pt-2 text-center">
    {{total | number}} {{(total === 1) ? plurals?.singular ?? 'result' : plurals?.plural ?? 'results' }} total
  </div>
  <select *ngIf="total > 10" class="form-select mb-2 mb-md-0 page-size" title="Set items per page" name="pageSize"
          [ngModel]="pageSize" (ngModelChange)="setPageSize($event)">
    <option [ngValue]="10">10 items per page</option>
    <option [ngValue]="25">25 items per page</option>
    <option [ngValue]="50">50 items per page</option>
    <option [ngValue]="100">100 items per page</option>
    <option [ngValue]="200">200 items per page</option>
    <option [ngValue]="500">500 items per page</option>
  </select>
  <button type="button" class="mb-2 btn btn-primary d-md-none" (click)="scrollToTop()">
    Back to top
  </button>
</div>