import { Location } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Debug } from '@app/core/debug';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { SubscriptionLike } from 'rxjs';
import { ApplyDateRangeButton, DateRangeOptions, DateRangeResult } from './date-range-picker.interfaces';
dayjs.extend(isSameOrAfter);

@Component({
  selector: 'date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent implements OnInit, OnChanges, OnDestroy {

  private readonly defaultOptions: DateRangeOptions = {
    beginDate: null,
    endDate: null,
    title: 'Select a date range',
    beginDateLabel: 'Begin Date',
    endDateLabel: 'End Date',
    placeholderText: 'mm/dd/yyyy',
    minDate: null,
    maxDate: null,
    allowNulls: true
  };

  @Input() options?: DateRangeOptions;
  title!: string; // Modal title
  beginDate!: Date | null; // Default begin date
  beginDateLabel!: string;
  endDate!: Date | null; // Default end date
  endDateLabel!: string;
  minDate: Date | null = null; // Minimum date allowed
  maxDate: Date | null = null; // Maximum date allowed
  helpMessage: string | null = null; // Error/help message text
  placeholderText!: string | null; // Text to place in text input when no date selected (same for both inputs)
  allowNulls!: boolean | null; // Allow user to leave one field blank (useful for "everything before/after XX date" style searches)
  dateRangeToApply: ApplyDateRangeButton | null = null; // Button to set provided date range.

  protected initBeginDate: Date;
  protected initEndDate: Date;

  private readonly sub: SubscriptionLike;

  constructor(private readonly modalInstance: NgbActiveModal, location: Location) {
    this.sub = location.subscribe(() => this.closeModal(true));
  }

  ngOnInit() {
    this.setOptions(this.options ?? this.defaultOptions);
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      this.setOptions(changes.options.currentValue); // If the options are changed mid-stream by the calling component, update them
    }
  }

  get dateRangeMatch() {
    if (!this.dateRangeToApply) {
      return false;
    }
    return !(this.hasDateChanged(this.dateRangeToApply.begin, this.beginDate) || this.hasDateChanged(this.dateRangeToApply.end, this.endDate));
  }

  get resetDateRangeMatch() {
    return !(this.hasDateChanged(this.initBeginDate, this.beginDate) || this.hasDateChanged(this.initEndDate, this.endDate));
  }

  // Sets values based on sent options. Uses defaults for any values not explicitly set
  private setOptions(options: DateRangeOptions = this.defaultOptions) {
    options = { ...this.defaultOptions, ...options }; // Fill in any options which weren't sent by the calling component
    Debug.debug('New date picker options:', options);
    this.title = options.title;
    this.initBeginDate = options.beginDate;
    this.beginDate = options.beginDate;
    this.placeholderText = options.placeholderText;
    this.beginDateLabel = options.beginDateLabel;
    this.initEndDate = options.endDate;
    this.endDate = options.endDate;
    this.endDateLabel = options.endDateLabel;
    this.minDate = options.minDate;
    this.maxDate = options.maxDate;
    this.allowNulls = options.allowNulls;
    this.dateRangeToApply = options.dateRangeToApply;
  }

  // Clear both inputs
  clear() {
    this.beginDate = null;
    this.endDate = null;
    this.isValid(this.beginDate, this.endDate);
  }

  // Reset button pressed, set to initial values when modal was first called
  reset() {
    this.beginDate = this.initBeginDate;
    this.endDate = this.initEndDate;
  }

  // Close modal and return selected dates, if any
  closeModal(cancelled = false) {
    if (cancelled) {
      // Still return a value in order retain compatibility with old code which expects a return value
      this.modalInstance.close({
        begin: this.initBeginDate,
        end: this.initEndDate,
        isChanged: false,
      } as DateRangeResult);
    } else if (this.isValid(this.beginDate, this.endDate)) {
      this.modalInstance.close({
        begin: this.beginDate,
        end: this.endDate,
        isChanged: this.hasDateChanged(this.initBeginDate, this.beginDate) || this.hasDateChanged(this.initEndDate, this.endDate)
      } as DateRangeResult);
    }
  }

  // Checks the entered date based on the options set, displays error if invalid
  isValid(begin: Date | null, end: Date | null) {
    if (begin && end && !dayjs(end).isSameOrAfter(begin, 'day')) {
      this.helpMessage = 'Beginning date must be before ending date';
      return false;
    } else if (!begin && !this.allowNulls) {
      this.helpMessage = `${this.beginDateLabel} must be provided`;
      return false;
    } else if (!end && !this.allowNulls) {
      this.helpMessage = `${this.endDateLabel} must be provided`;
      return false;
    } else {
      this.helpMessage = null;
      return true;
    }
  }

  // return false if both dates are null or have not changed, otherwise true
  hasDateChanged(date1: Date | null, date2: Date | null) {
    if (!date1 && !date2) {
      return false;
    }
    return !dayjs(date1).isSame(date2, 'day');
  }

  setDates() {
    this.beginDate = this.dateRangeToApply.begin;
    this.endDate = this.dateRangeToApply.end;
    this.isValid(this.beginDate, this.endDate);
  }
}
