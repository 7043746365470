import { inject } from "@angular/core";
import { CanActivateChildFn, CanActivateFn } from "@angular/router";
import { environment } from "@env";
import { DirectoryService } from "../directory";
import { WindowService } from "../window.service";
import { ProfileService } from "./profile.service";

export const AuthGuard: CanActivateFn | CanActivateChildFn = async (route, state) => {
  const profileService = inject(ProfileService);
  const directoryService = inject(DirectoryService);
  const windowService = inject(WindowService);

  if (environment.bypassAuth) {
    return true;
  }
  profileService.isProtectedRoute = true;
  const profile = await profileService.getProfile();
  if (!profile.isAuthenticated) {
    profileService.expiredSessionRedirect();
    return false;
  }

  const routePermissions: string[] = route.data.permissions || [];
  const routeRoles: string[] = route.data.roles || [];

  if ((routePermissions.length && !routePermissions.every(p => profile.permissions.includes(p)))
    || (routeRoles.length && !routeRoles.every(p => profile.roles.includes(p)))) {
    const headerData = directoryService.getDefinition('header', '^1.0.4');
    const producerLookupUrl = environment.rampProducerLookupUri;
    const redirectUrl: string = headerData?.homeUrl || producerLookupUrl;
    windowService.nativeWindow.location.assign(redirectUrl);
    return false;
  }

  if (
    // If they're still onboarding
    profile.permissions.includes('restricted-user')
    // And they're trying to access a non-onboarding url
    && !(state.url.startsWith('/onboarding') || state.url.startsWith('/portal-client/onboarding'))
  ) {
    // Just say no to hackers
    return false;
  }

  return profile.isAuthenticated;
};