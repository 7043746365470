import { Injectable } from '@angular/core';
import { ClientPagingResult } from "@core/client-paging/client-paging.interfaces";
import { isEqual } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ClientPagingService {

  constructor() { }

  getDisplayData<T>(allData: T[], currentPage: T[], pageSize: number, pageNumber: number): ClientPagingResult<T> | null {
    const newPageView = this.paginateArray(allData, pageSize, pageNumber);
    if (this.hasChanges(currentPage, newPageView.data)) {
      return newPageView;
    }
    return null;
  }

  private paginateArray<T>(allData: T[], pageSize: number, pageNumber: number): ClientPagingResult<T> {
    const totalPages = Math.ceil(allData.length / pageSize);
    const validPageNumber = Math.max(1, Math.min(pageNumber, totalPages));
    const startIndex = (validPageNumber - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, allData.length);
    return {
      data: allData.slice(startIndex, endIndex),
      pageNumber: validPageNumber
    } as ClientPagingResult<T>;
  }

  private hasChanges<T>(oldPageView: T[], newPageView: T[]): boolean {
    return oldPageView.length !== newPageView.length || oldPageView.some((value, index) => !isEqual(value, newPageView[index]));
  }
}
